.evaluationContainer {
  max-width: 295px;
  border-radius: 8px;
  height: 109px;
  background-color: #fff;
  align-self: start;
  align-items: flex-start;
  box-shadow: 0px 2px 2px rgba(46, 45, 55, 0.08);
  margin-bottom: 18px;

  .titleBox {
    background-color: #F0F0F0;
    padding: 10px 16px;
    border-radius: 8px 8px 0 0;
  }

  .contentEmoji {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 24px;
  }
}

.evaluationEmoji {
  border: 0;
  margin: 0;
  outline: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  * {
    transition: all ease .3s;  
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-height: 100%;
    font-size: 36px;

    svg {
      width: 36px;
      height: 36px;
      * {
        transition: all ease .3s;  
      }
    }
  }

}
