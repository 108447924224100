.loadContainer {
  margin-bottom: 32px;
}

.loadItem,
.loadContainer {
  max-width: 100%;
  box-sizing: border-box;
}

.loadItem {
  display: flex;
  align-items: center;
  position: relative;
  background: #E6E8EC;
  border-radius: 0px 16px 16px 16px;
  height: 36px;
  width: 70px;

  div {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 10px;
      animation: ellipsis1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 10px;
      animation: ellipsis2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 30px;
      animation: ellipsis2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 50px;
      animation: ellipsis3 0.6s infinite;
    }
  }
}

@keyframes ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(20px, 0);
  }
}
